import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { MainContainer } from "../../common/layout/Layout.style";
import Joi from "joi-browser";
import Select from "react-select";
import { style } from "../../common/select-style/SelectStyle";
import { handleSave, validateForm } from "../../common/validation/validation";
import { Contract } from "../../models/ContractModel";
import CustomDatePicker from "../../common/custom-date-picker/CustomDatePicker.component";
import Company from "../../components/admin-panel/company/company.component";
import Client from "../../api-services/sap-contract/client";

const CreateContract = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [contract, setContract] = useState(new Contract());
  const [edit, setEdit] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState({});

  const client = new Client();

  const contractTypes = [
    {
      value: 1,
      label: "Ugovor sa rokom važenja",
    },
    {
      value: 2,
      label: "Ugovor bez roka važenja",
    },
  ];

  const schema = {
    contractType: Joi.optional().required(),
    contractNumber: Joi.string().required(),
    contractingParty: Joi.string().required(),
    companyIdentifier: Joi.string(),
    vendorContractNumber: Joi.string().required(),
    validityPeriodDateFrom: Joi.date().required(),
    validityPeriodDateTo: Joi.date().required(),
    warnityPeriodDateFrom: Joi.date().required(),
    warnityPeriodDateTo: Joi.date().required(),
  };

  useEffect(() => {
    if (id) getContract(id);
    else setDisabled(false);
  }, [id]);

  const handlePropertyChange = (prop, value) => {
    const obj = { name: prop, value: value };
    const result = handleSave(obj, contract, errors, schema, t);
    setContract(result.data);
    setErrors(result.errors);
  };

  const getContract = async (id) => {
    let response = await client.GetContract(id);
    setContract(response);
    setDisabled(!response.canChangeContract);
    setEdit(true);
  };

  const createContract = async () => {
    if (edit) var response = await client.UpdateContract(contract);
    else var response = await client.CreateContract(contract);
  };

  return (
    <MainContainer>
      <h4>{edit ? t("ContractEdit") : t("ContractCreate")}</h4>
      <Company user={null} handleChange={handlePropertyChange} edit={edit} />
      <div className="form-group col-md-4">
        <label>{t("ContractType")}</label>
        <Select
          placeholder={t("SelectFromList")}
          options={contractTypes}
          onChange={(e) => {
            handlePropertyChange("contractType", e.value);
          }}
          menuPortalTarget={document.body}
          styles={style}
          value={contractTypes.filter((x) => x.value == contract.contractType)}
          isDisabled={disabled}
        />
      </div>
      <div className="form-group col-md-4">
        <label>{t("ContractNumber")}</label>
        <input
          type="text"
          className="form-control"
          value={contract.contractNumber}
          onChange={(e) =>
            handlePropertyChange("contractNumber", e.target.value)
          }
          disabled={disabled}
        />
        {errors?.name && (
          <div className="text-danger">{errors.contractNumber}</div>
        )}
      </div>
      <div className="form-group col-md-4">
        <label>{t("ContractingParty")}</label>
        <input
          type="text"
          className="form-control"
          value={contract.contractingParty}
          onChange={(e) =>
            handlePropertyChange("contractingParty", e.target.value)
          }
          disabled={disabled}
        />
        {errors?.name && (
          <div className="text-danger">{errors.contractingParty}</div>
        )}
      </div>
      <div className="form-group col-md-4">
        <label>{t("VendorContractNumber")}</label>
        <input
          type="text"
          className="form-control"
          value={contract.vendorContractNumber}
          onChange={(e) =>
            handlePropertyChange("vendorContractNumber", e.target.value)
          }
          disabled={disabled}
        />
        {errors?.name && (
          <div className="text-danger">{errors.vendorContractNumber}</div>
        )}
      </div>
      <div className="form-group col-md-4">
        <label>{t("ValidityDateFrom")}</label>
        <br />
        <CustomDatePicker
          selected={contract.validityPeriodDateFrom}
          onChange={(date) =>
            handlePropertyChange("validityPeriodDateFrom", date)
          }
          disabled={disabled}
        />
        {errors?.name && (
          <div className="text-danger">{errors.validityPeriodDateFrom}</div>
        )}
      </div>
      {contract.contractType == 1 && (
        <>
          <div className="form-group col-md-4">
            <label>{t("ValidityDateTo")}</label>
            <br />
            <CustomDatePicker
              selected={contract.validityPeriodDateTo}
              onChange={(date) =>
                handlePropertyChange("validityPeriodDateTo", date)
              }
              disabled={disabled}
            />
            {errors?.name && (
              <div className="text-danger">{errors.validityPeriodDateTo}</div>
            )}
          </div>
          <div className="form-group col-md-4">
            <label>{t("WarnityDateFrom")}</label>
            <br />
            <CustomDatePicker
              selected={contract.warnityPeriodDateFrom}
              onChange={(date) =>
                handlePropertyChange("warnityPeriodDateFrom", date)
              }
              disabled={disabled}
            />
            {errors?.name && (
              <div className="text-danger">{errors.warnityPeriodDateFrom}</div>
            )}
          </div>
          <div className="form-group col-md-4">
            <label>{t("WarnityDateTo")}</label>
            <br />
            <CustomDatePicker
              selected={contract.warnityPeriodDateTo}
              onChange={(date) =>
                handlePropertyChange("warnityPeriodDateTo", date)
              }
              disabled={disabled}
            />
            {errors?.name && (
              <div className="text-danger">{errors.warnityPeriodDateTo}</div>
            )}
          </div>
        </>
      )}
      {edit && (
        <div className="form-group col-md-4">
          <label>{t("Active")} &nbsp;</label>
          <input
            type="checkbox"
            value={contract.unlimitedValidity}
            checked={contract.unlimitedValidity}
            onChange={() =>
              setContract({
                ...contract,
                unlimitedValidity: !contract.unlimitedValidity,
              })
            }
            disabled={disabled}
          />
        </div>
      )}
      <button type="button" className="btn btn-accept" onClick={createContract}>
        {edit ? t("Change") : t("Create")}
      </button>
    </MainContainer>
  );
};

export default CreateContract;
