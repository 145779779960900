import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { MainContainer } from "../../../common/layout/Layout.style";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
} from "../../../common/validation/validation";
import { CompanyModel } from "../../../models/CompanyModel";
import Client from "../../../api-services/admin-panel/client";

const CreateCompany = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [company, setCompany] = useState(new CompanyModel());
  const [edit, setEdit] = useState(false);
  const [errors, setErrors] = useState({});

  const schema = {
    companyName: Joi.string().required(),
    address: Joi.string().required(),
    email: Joi.string().email().required(),
    pib: Joi.string().required(),
  };

  var client = new Client();

  useEffect(() => {
    if (id) {
      getCompany(id);
    }
  }, [id]);

  const getCompany = async (companyId) => {
    let response = await client.GetCompany(companyId);
    setCompany(response);
    setEdit(response.canChangeCompany);
  };

  const handlePropertyChange = (prop, value) => {
    const obj = { name: prop, value: value };
    const result = handleSave(obj, company, errors, schema, t);
    setCompany(result.data);
    setErrors(result.errors);
  };

  const createOrUpdateCompany = async () => {
    if (edit) var response = await client.UpdateCompany(company);
    else var response = await client.CreateCompany(company);
    if (response != "Error") {
      navigate("/AdminPanel/Companies");
    }
  };

  return (
    <MainContainer>
      <h4>
        {edit
          ? t("CompanyEdit")
          : id
          ? t("CompanyPreview")
          : t("CompanyCreate")}
      </h4>
      <div className="form-group col-md-4">
        <label>{t("company")}</label>
        <input
          type="text"
          className="form-control"
          value={company.companyName}
          onChange={(e) => handlePropertyChange("companyName", e.target.value)}
        />
        {errors?.name && (
          <div className="text-danger">{errors.companyName}</div>
        )}
      </div>
      <div className="form-group col-md-4">
        <label>{t("companyAddress")}</label>
        <input
          type="text"
          className="form-control"
          value={company.address}
          onChange={(e) => handlePropertyChange("address", e.target.value)}
        />
        {errors?.name && <div className="text-danger">{errors.address}</div>}
      </div>
      <div className="form-group col-md-4">
        <label>{t("companyEmail")}</label>
        <input
          type="text"
          className="form-control"
          value={company.email}
          onChange={(e) => handlePropertyChange("email", e.target.value)}
        />
        {errors?.name && <div className="text-danger">{errors.email}</div>}
      </div>
      <div className="form-group col-md-4">
        <label>{t("PIB")}</label>
        <input
          type="text"
          className="form-control"
          value={company.pib}
          onChange={(e) => handlePropertyChange("pib", e.target.value)}
        />
        {errors?.name && <div className="text-danger">{errors.pib}</div>}
      </div>
      <div className="form-group col-md-4">
        <label>{t("daughterCompany")} &nbsp;</label>
        <input
          type="checkbox"
          value={company.daughterCompany}
          checked={company.daughterCompany}
          disabled={id && !edit}
          onChange={() => {
            setCompany({
              ...company,
              daughterCompany: !company.daughterCompany,
            });
          }}
        />
      </div>
      {(!id || edit) && (
        <button
          type="button"
          className="btn btn-accept"
          onClick={createOrUpdateCompany}
        >
          {edit ? t("Change") : t("Create")}
        </button>
      )}
    </MainContainer>
  );
};

export default CreateCompany;
