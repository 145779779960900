import api from "../../api";
import HandlingResponse from "../../common/handling-response/handlingResponse";
import { Success } from "../../common/toastify/toastify";
import { env } from "../../env";

var baseURL = env.REACT_APP_API_URL_AAMS;
var successCode = "200";

class Client {
  async SearchSAPContracts(data, isBackground) {
    try {
      var response = await api.post(baseURL + "/SAPContract/Search", data, {
        isBackground: isBackground,
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async Sync() {
    try {
      var response = await api.get(baseURL + "/SAPContract/Sync");
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async SetWarnityPeriod(data) {
    try {
      var response = await api.post(
        baseURL + "/SAPContract/SetWarnintyPeriod",
        data
      );
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async GetContract(id) {
    try {
      var response = await api.get(baseURL + "/SAPContract/Get?id=" + id);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async CreateContract(data) {
    try {
      var response = await api.post(baseURL + "/SAPContract/CreateContract", data);
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async UpdateContract(data) {
    try {
      var response = await api.post(baseURL + "/SAPContract/Update", data);
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }

  async DeleteContract(id) {
    try {
      var response = await api.delete(baseURL + "/SAPContract/Delete?id=" + id);
      Success(successCode);
      return response.data;
    } catch (err) {
      if (err.response.status === 400)
        return HandlingResponse(err.response.data);
      else return HandlingResponse(err.response);
    }
  }
}

export default Client;
