import { useTranslation } from "react-i18next";
import { useEffect, useState, useRef } from "react";
import Table from "../../../common/table/Table.component";
import DialogModal from "../../../common/modal/Modal.component";
import CreateInputPerson from "../../../components/bzr-service/create-input-person/createInputPerson.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { InputPersonModel } from "../../../models/InputModels";
import {
  DeleteButton,
  NewFileBtn,
} from "../../../common/multiple-files/multipleFiles.style";
import Joi from "joi-browser";
import { validateForm } from "../../../common/validation/validation";
import Client from "../../../api-services/bzr-service/client";

const InputPersons = ({ handleAddPerson, persons, withBzr, disabled }) => {
  const { t } = useTranslation();
  const tableName = "Input_Persons";

  const [edit, setEdit] = useState(false);
  const [personIndex, setPersonIndex] = useState(-1);
  const [personData, setPersonData] = useState(new InputPersonModel());
  const [errors, setErrors] = useState({});
  const [personIdError, setPersonIdError] = useState(false);
  const [passwordNumberError, setPasswordNumberError] = useState(false);
  
  const uploadRef = useRef(null);
  const [uploadPersons, setUploadPersons] = useState();

  const columns = [
    {
      Header: t("Name"),
      accessor: "firstName",
    },
    {
      Header: t("LastName"),
      accessor: "lastName",
    },
    {
      Header: t("Workplace"),
      accessor: "employeePosition",
    },
    {
      Header: t("IdentityCardNumber"),
      accessor: "identityCardNumber",
    },
    {
      Header: t("IdentityCardPlace"),
      accessor: "identityCardPlace",
    },
    {
      Header: t("PassportNumber"),
      accessor: "identityPassportNumber",
    },
    {
      Header: t("PassportPlace"),
      accessor: "identityPassportPlace",
    },
    {
      Header: t("Foreigner"),
      accessor: "foreigner",
      Cell: ({ row }) => {
        return <>{row.original.foreigner ? t("Yes") : t("No")}</>;
      },
    },
    {
      accessor: "edit",
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-incomplete table-btn"
              title={t("ChangePerson")}
              onClick={() => {
                setEdit(true);
                setPersonIndex(row.index);
                setPersonData(row.original);
                ShowDialog();
              }}
            >
              <FontAwesomeIcon icon={solid("pencil")} />
            </button>
            <button
              type="button"
              className="btn btn-danger table-btn"
              title={t("DeletePerson")}
              onClick={() => removePerson(row.index)}
            >
              <FontAwesomeIcon icon={solid("trash")} />
            </button>
          </>
        );
      },
    },
    {
      accessor: "notValid",
      className: "one-button-column",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.notValid && (
              <FontAwesomeIcon icon={solid("triangle-exclamation")} />
            )}
          </div>
        );
      },
    },
  ];

  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);

  const schema = {
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
    employeePosition: Joi.optional(),
    identityCardNumber: Joi.string().length(9).allow(""),
    identityCardPlace: Joi.optional(),
    identityPassportNumber: Joi.optional(),
    identityPassportPlace: Joi.optional(),
  };

  var client = new Client();

  useEffect(() => {
    if (persons) {
      setData(persons);
    }
  }, [persons]);

  const ShowDialog = () => setShow(true);
  const HideDialog = () => setShow(false);

  const addPerson = (person) => {
    let index = edit ? personIndex : -1;
    let personIdExist = person.foreigner
      ? false
      : data.filter(
          (x, i) =>
            i != index &&
            x.identityCardNumber == person.identityCardNumber &&
            person.identityCardNumber != ""
        ).length > 0;

    const passwordNumberExist = !person.foreigner
      ? false
      : data.filter(
          (x, i) =>
            i !== index &&
            x.identityPassportNumber === person.identityPassportNumber &&
            person.identityPassportNumber !== ""
        ).length > 0;

    if (personIdExist) {
      setPersonIdError(true);
    } else if (passwordNumberExist) {
      setPasswordNumberError(true);
    } else {
      person.notValid = false;
      setPersonIdError(false);
      setPasswordNumberError(false);
      HideDialog();
      if (edit) {
        let dataCopy = data;
        dataCopy[personIndex] = person;
        setData(dataCopy);
        handleAddPerson(dataCopy);
      } else {
        setData([...data, person]);
        handleAddPerson([...data, person]);
      }
    }
  };

  const removePerson = (index) => {
    let newData = data.filter((v, i) => i !== index);
    setData(newData);
    handleAddPerson(newData);
  };

  const validatePerson = (person) => {
    if (person.foreigner) {
      schema.identityPassportNumber = Joi.string().required();
      schema.identityPassportPlace = Joi.string().required();
      schema.identityCardNumber = Joi.optional();
      schema.identityCardPlace = Joi.optional();
    } else {
      schema.identityCardNumber = Joi.string().length(9).required();
      schema.identityCardPlace = Joi.string().required();
      schema.identityPassportNumber = Joi.optional();
      schema.identityPassportPlace = Joi.optional();
    }

    const errorsResult = validateForm(person, schema, t);
    return errorsResult;
  }

  const handleUploadClick = () => {
    uploadRef.current.value = null;
    uploadRef.current?.click();
  };

  const getPersonsFromExcel = async (file) => {
    const formData = new FormData();
    formData.append("excelFile", uploadPersons);
    let response = await client.GetPersonsFromExcel(formData);
    let personsFromExcel = response.map(person => {
      let isForeigner = person.foreigner == "Da";
      let data = {
        ...person,
        foreigner: isForeigner,
        identityCardNumber: isForeigner ? "" : person.identityNumber,
        identityCardPlace: isForeigner ? "" : person.identityPlace,
        identityPassportNumber: isForeigner ? person.identityNumber : "",
        identityPassportPlace: isForeigner ? person.identityPlace : "",
      };
      let notValid = validatePerson(data);
      data.notValid = notValid;
      return data;
    });
    setData([...data, ...personsFromExcel]);
    handleAddPerson([...data, ...personsFromExcel]);
  };

  const removeUploadPersons = () => {
    setUploadPersons(null);
    uploadRef.current.value = null;
  };

  const downloadTemplate = async () => {
    let response = await client.DownloadTemplateForPersons();
  };

  return (
    <div style={{ marginBottom: "10px" }}>
      {!disabled && (
        <>
          <h5 className="light-header">{t("AddingPersonsFromExcel")}</h5>
          <div>
            <button
              type="button"
              className="btn btn-primary"
              style={{ marginRight: "20px" }}
              onClick={downloadTemplate}
            >
              {t("DownloadTemplate")}
            </button>
            {uploadPersons ? (
              <>
                {uploadPersons?.name}
                <DeleteButton
                  title={t("DeleteFile")}
                  type="button"
                  onClick={() => removeUploadPersons()}
                >
                  <FontAwesomeIcon icon={solid("times")} />
                </DeleteButton>
              </>
            ) : (
              <NewFileBtn onClick={handleUploadClick} type="button">
                <FontAwesomeIcon icon={solid("plus")} />
                {t("UploadPersons")}
              </NewFileBtn>
            )}
            <input
              type="file"
              ref={uploadRef}
              onChange={(event) => setUploadPersons(event.target.files[0])}
              hidden
              accept=".xlsx"
              name="uploadRef"
            />
            <button
              type="button"
              className="btn btn-primary"
              onClick={getPersonsFromExcel}
              disabled={!uploadPersons}
            >
              {t("AddPersons")}
            </button>
          </div>
          <br />
        </>
      )}
      {data.length > 0 && (
        <>
          <h5>{t("InputPersons")}</h5>
          <Table
            columns={columns}
            data={data}
            tableName={tableName}
            rememberFilter={false}
            hiddenColumn={disabled && "edit"}
          />
        </>
      )}
      {!disabled && (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            setEdit(false);
            setPersonIndex(-1);
            setPersonData({
              ...new InputPersonModel(),
              bzrWithContract: withBzr,
            });
            ShowDialog();
          }}
        >
          {t("AddInputPerson")}
        </button>
      )}
      <DialogModal
        show={show}
        onHide={HideDialog}
        title={edit ? t("InputPersonUpdate") : t("InputPerson")}
      >
        <CreateInputPerson
          withContract={true}
          addPerson={addPerson}
          personData={personData}
          isEdit={edit}
          inputPerson={true}
          errors={errors}
          setErrors={setErrors}
          personIdError={personIdError}
          setPersonIdError={setPersonIdError}
          passwordNumberError={passwordNumberError}
          setPasswordNumberError={setPasswordNumberError}
        />
      </DialogModal>
    </div>
  );
};

export default InputPersons;
