import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SAPContract } from "../../../models/SAPContractModel";
import { MainContainer } from "../../../common/layout/Layout.style";
import CustomDatePicker, { dateWithoutTimezone } from "../../../common/custom-date-picker/CustomDatePicker.component";
import DialogModal from "../../../common/modal/Modal.component";
import Table from "../../../common/table/Table.component";
import Client from "../../../api-services/sap-contract/client";
import UserService from "../../../utils/authentification/keycloak";

const PreviewContract = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [contract, setContract] = useState(new SAPContract());
  const [show, setShow] = useState(false);
  const [warnityPeriod, setWarnityPeriod] = useState({
    id: 0,
    warnityPeriodDateFrom: dateWithoutTimezone(new Date()),
    warnityPeriodDateTo: dateWithoutTimezone(new Date()),
  });
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [authorizedPersons, setAuthorizedPersons] = useState([]);
  const columns = [
    {
      Header: t("NameAndLastName"),
      accessor: "firstAndLastName",
    },
    {
      Header: t("Email"),
      accessor: "email",
    },
  ];

  var client = new Client();

  useEffect(() => {
    getContract(id);
  }, []);

  const ShowDialog = () => setShow(true);
  const HideDialog = () => setShow(false);

  const getContract = async (id) => {
    let data = await client.GetContract(id);
    setContract(data);
    setWarnityPeriod({
      id: data.id,
      warnityPeriodDateFrom: data.warnityPeriodDateFrom,
      warnityPeriodDateTo: data.warnityPeriodDateTo,
    });
    setAuthorizedPersons(data.authorizedPersons);
    let employeeId = UserService.getEmployeeId();
    let employeeAuthorized = data.authorizedPersons.filter(
      (x) => x.employeeId == employeeId
    );
    if (employeeAuthorized.length > 0) {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  };

  const addWarnityPeriod = async () => {
    let response = await client.SetWarnityPeriod(warnityPeriod);
    HideDialog();
    if (response != "Error") {
      setContract(response);
    }
  };

  return (
    <MainContainer>
      <h5>{t("PreviewContract")}</h5>
      <br />
      <div className="row">
        <div className="form-group col-md-3">
          <label>{t("ContractNumber")}</label>
          <input
            type="text"
            className="form-control"
            value={contract.contractNumber}
            disabled
          />
        </div>
        <div className="form-group col-md-3">
          <label>{t("ContractingParty")}</label>
          <input
            type="text"
            className="form-control"
            value={contract.contractingParty}
            disabled
          />
        </div>
        <div className="form-group col-md-3">
          <label>{t("VendorContractNumber")}</label>
          <input
            type="text"
            className="form-control"
            value={contract.vendorContractNumber}
            disabled
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-3">
          <label>{t("Address")}</label>
          <input
            type="text"
            className="form-control"
            value={contract.address}
            disabled
          />
        </div>
        <div className="form-group col-md-3">
          <label>{t("Email")}</label>
          <input
            type="text"
            className="form-control"
            value={contract.email}
            disabled
          />
        </div>
      </div>
      <div className="row col-md-10">
        <div className="form-group col-md-2">
          <label>{t("ValidityDateFrom")}</label>
          <br />
          <CustomDatePicker
            selected={contract.validityPeriodDateFrom}
            disabled
          />
        </div>
        <div className="form-group col-md-2">
          <label>{t("ValidityDateTo")}</label>
          <br />
          <CustomDatePicker
            selected={contract.validityPeriodDateTo}
            disabled
          />
        </div>
        <div className="form-group col-md-2">
          <label>{t("WarnityDateFrom")}</label>
          <br />
          <CustomDatePicker
            selected={contract.warnityPeriodDateFrom}
            disabled
          />
        </div>
        <div className="form-group col-md-2">
          <label>{t("WarnityDateTo")}</label>
          <br />
          <CustomDatePicker
            selected={contract.warnityPeriodDateTo}
            disabled
          />
        </div>
        {isAuthorized && (
          <div className="col-md-2">
            <button
              type="button"
              className="btn btn-primary"
              style={{ marginTop: "28px" }}
              onClick={ShowDialog}
            >
              {t("AddWarnityDate")}
            </button>
          </div>
        )}
      </div>
      <br />
      <h5>{t("AuthorizedPersons")}</h5>
      {authorizedPersons.length > 0 ? (
        <Table
          columns={columns}
          data={authorizedPersons}
          tableName={"Authorized_Persons"}
          rememberFilter={false}
        />
      ) : (
        <span>Nema ovlašćenih lica.</span>
      )}
      <DialogModal
        show={show}
        onHide={HideDialog}
        title={t("AddingWarnityDate")}
      >
        <>
          <div className="form-group">
            <label>{t("WarnityDateStartFrom")}</label>
            <br />
            <CustomDatePicker
              selected={warnityPeriod.warnityPeriodDateFrom ? warnityPeriod.warnityPeriodDateFrom : dateWithoutTimezone(new Date())}
              onChange={(date) => {
                setWarnityPeriod({
                  ...warnityPeriod,
                  warnityPeriodDateFrom: date,
                });
              }}
            />
          </div>
          <div className="form-group">
            <label>{t("WarnityDateEndTo")}</label>
            <br />
            <CustomDatePicker
              selected={warnityPeriod.warnityPeriodDateTo ? warnityPeriod.warnityPeriodDateTo : dateWithoutTimezone(new Date())}
              onChange={(date) => {
                setWarnityPeriod({
                  ...warnityPeriod,
                  warnityPeriodDateTo: date,
                });
              }}
            />
          </div>
          <button
            type="button"
            className="btn btn-primary pull-right"
            onClick={addWarnityPeriod}
          >
            {t("AddWarnityDate")}
          </button>
        </>
      </DialogModal>
    </MainContainer>
  );
};

export default PreviewContract;
